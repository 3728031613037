import React from 'react'
import RingBuilderImg from "../../../Assets/images/ring-builder-bgg.png"
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const RingBuilder = () => {
  return (
    <div className='ring-builder-section' style={{background: `url(${RingBuilderImg}) no-repeat center/cover`}}>
        <Container>
            <Row>
                <Col lg={6} md={9}>
                    <h2>CREATE YOUR OWN DIAMOND RING</h2>
                    <p className='mt-2 mb-0'>Select a setting and choose a diamond to create your own diamond engagement ring.</p>
                    <p className='mt-1 mb-4'>Need help? We're always here.</p>
                    <div className='button-flex'>
                        <Link className="btn theme-btn solid mr-md-3 mr-0 brownBtn" to="/ringsettings">Start With A Setting</Link>
                        <Link className="btn theme-btn outline brownBtnBorder" to="/diamonds">Start With A Diamond</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default RingBuilder