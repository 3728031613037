import React from 'react'
import { Container, Image } from 'react-bootstrap'
import Img from "./images/fancy.png"
import { Link } from 'react-router-dom/cjs/react-router-dom'

const FancyColorDiamonds = () => {
  return (
    <div className='fancy-color-diamond-section'>
        <Container>
            <div className='fancy-heading mb-5'>
                <h3>Fancy color diamonds</h3>
            </div>

            <div><Image src={Img} alt='' /></div>
            <Link className="btn theme-btn solid brownBtn" to="/fancy-color-diamond">Shop Fancy Diamonds</Link>
        </Container>
    </div>
  )
}

export default FancyColorDiamonds