import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Insta = () => {
    return (
        <div className='pt-5 mt-5'>
            <div className='fancy-heading mb-5 text-center'>
                <h3>View our Instagram</h3>
            </div>
            <iframe src="https://instagram.demobw.live/big-diamonds/" frameborder="0" width="100%" class="insta-iframe"></iframe>
        </div>
    )
}

export default Insta;