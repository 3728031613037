import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

const HomeColSection = ({children, row, image}) => {
    if(row !== "reverse") return (
        <div className='home-column-section'>
            <Row className='m-0'>
                <Col lg={6} md={12} className="p-0">
                    <div className='image'>
                        <Image src={image} about='' />
                    </div>
                </Col>
    
                <Col lg={6} md={12} className="p-0">
                    <div className='content'>
                        {children}
                    </div>
                </Col>
            </Row>
        </div>
    )
    else return (
        <div className='home-column-section'>
            <Row className='m-0'>
                <Col lg={6} md={12} className="p-0 order-lg-1 order-2">
                    <div className='content'>
                        {children}
                    </div>
                </Col>
                <Col lg={6} md={12} className="p-0 order-lg-2 order-1">
                    <div className='image'>
                        <Image src={image} about='' />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default HomeColSection