import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button, Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Col, Container, Image, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';
import Video from "../../Assets/images/BannerVideo.mp4";

const BannerSlider = () => {
  const history = useHistory();
  const [banner,setBanner] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
 //-----------------------------------Banner
 setLoading(true)
 axios.get(base_url + `/home/slider`, {
  headers: postHeader
})
  .then(response => {
    if (response.data.status == 1) {
      setLoading(false)
      setBanner(response.data.data);
      localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  })
  .catch((error) => {
    console.log(error);
  });
  },[])
  return (
    <>
      <div>
        <video src={Video} className='w-100' autoPlay muted controls />
      </div>

      {banner?.map((val, index) =>
        <>
          {!loading?  <div className="rcs_hero_slider">
            <div className="rcs_hero_img">
              <div className='banner-slider'>
                <Image src={isMobileOnly ? val?.mobile_image : val?.image} alt="" />
                <div className={isMobile ? 'text-md-left text-center inner-banner-wrap' : val.text_direction_class + " inner-banner-wrap"}>
                  <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                  <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                  {/* <Button className='brownBtnBorder' data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button> */}
                </div>
              </div>         
            </div>
          </div>:<Skeleton variant="text" animation="wave"/>}
        </>
      )}
      
    </>
  )
}

export default BannerSlider;