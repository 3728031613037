import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Cd1 from "./images/c1.jpg"
import Cd2 from "./images/c2.jpg"
import Cd3 from "./images/c3.jpg"
import { useHistory } from 'react-router-dom'

const CustomDesign = () => {
    const history = useHistory();
  return (
    <div className='custom-design-section'>
       <Container>
            <div className='fancy-heading'>
                <h3>Custom Design</h3>
                <p>Create a beautiful, one-of-a-kind representation of your unique love.</p>
            </div>

            <div className='mt-5'>
                <Row>
                    <Col md={4}>
                        <div className='cd-item text-center'>
                            {/* <Image src={Cd1} alt='' /> */}
                            <Image src={require("../../../Assets/images/first_rem_bg.png")} alt='' />
                            <h2 className='my-4' onClick={()=> history.push("/custom-design")}>Diamond</h2>
                            <p>From modern to vintage inspired designs, our extensive collection is sure to hold a ring that meets your style and budget.</p>
                        </div>
                    </Col>
                    
                    <Col md={4}>
                        <div className='cd-item text-center'>
                            {/* <Image src={Cd2} alt='' /> */}
                            <Image src={require("../../../Assets/images/c22.png")} alt='' />
                            <h2 className='my-4' onClick={()=> history.push("/custom-design")}>Custom Design</h2>
                            <p>Our designers use your ideas, drawings, and images to visualize your exclusive design concept.</p>
                        </div>
                    </Col>
                    
                    <Col md={4}>
                        <div className='cd-item text-center'>
                            {/* <Image src={Cd3} alt='' /> */}
                            <Image src={require("../../../Assets/images/c23.png")} alt='' />
                            <h2 className='my-4' onClick={()=> history.push("/custom-design")}>Delivery</h2>
                            <p>Once you’ve chosen your setting and diamond, you’ll be able to review your completed ring. You can even add engraving to personalize your choice further.</p>
                        </div>
                    </Col>
                </Row>
            </div>
       </Container>
    </div>
  )
}

export default CustomDesign