import Aos from 'aos';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { client_name } from '../../../Helpers/request';
import BannerSlider from '../Bannerslider';
import HomeColSection from './HomeColSection';
import Insta from './insta';
import RingBuilder from './RingBuilder';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import FancyColorDiamonds from './FancyColorDiamonds';
import CustomDesign from './CustomDesign';
import SignupTo from '../../Front/SignupTo'

import Sec1 from "./images/section-1.jpg"
import Sec2 from "./images/section-2.jpg"
import Sec3 from "./images/section-3.jpg"
import Sec4 from "./images/section-4.jpg"
import Sec5 from "./images/section-5.jpg"
import { Col, Container, Row } from 'react-bootstrap';

const Home = () => {
    const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  
    useEffect(() => {
      Aos.init({
        duration: 1000,
        // disable: 'mobile'
      });
    }, []);
  
  
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaDetails?.title}</title>
          <meta name="description" content={metaDetails?.description}></meta>
          <meta name="keywords" content={metaDetails?.keywords}></meta>
          {/*Og Tags */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaDetails?.title} />
          <meta property="og:description" content={metaDetails?.description} />
          <meta property="og:url" content={metaDetails?.url} />
          <meta property="og:site_name" content={client_name?.c_name} />
        </Helmet>
        <BannerSlider />
        

        <HomeColSection image={Sec1}>
          <div class="inner_right_bx buy-lab-grown-dia">
            <p class="overline">BUY LAB GROWN DIAMONDS</p>
            <h2 class="red-underline">Lab Grown Diamonds</h2>
            <p class="txt_body">A diamond is a diamond, whether pulled from the earth or grown in a lab.</p>
            <p class="txt_link m-0"><Link to="/diamonds/lab" className="brownBtnBorderBox">Shop Now</Link></p>
          </div>
        </HomeColSection>

        <RingBuilder />
        
        <HomeColSection image={Sec2}>
          <div class="inner_right_bx">
            <h2 class="red-underline">Diamond Earrings</h2>
            <p class="txt_body">With a Lifetime Manufacturing Defect Warranty, Price Match Guarantee, 30-day return policy and U.S. craftsmanship, you can be confident in each and every purchase.</p>
            <p class="txt_link m-0"><Link to="/jewelry/earrings" className="brownBtnBorderBox">Shop Now</Link></p>
          </div>
        </HomeColSection>

        <HomeColSection row="reverse" image={Sec3}>
          <div class="inner_right_bx">
            <h2 class="red-underline">Diamond Bracelets</h2>
            <p class="txt_body">With a Lifetime Manufacturing Defect Warranty, Price Match Guarantee, 30-day return policy and U.S. craftsmanship, you can be confident in each and every purchase.</p>
            <p class="txt_link m-0"><Link to="/jewelry/bracelets" className="brownBtnBorderBox">Shop Now</Link></p>
          </div>
        </HomeColSection>
        
        <HomeColSection image={Sec4}>
          <div class="inner_right_bx">
            <h2 class="red-underline">Diamond  Necklaces</h2>
            <p class="txt_body">With a Lifetime Manufacturing Defect Warranty, Price Match Guarantee, 30-day return policy and U.S. craftsmanship, you can be confident in each and every purchase.</p>
            <p class="txt_link m-0"><Link to="/jewelry/necklaces" className="brownBtnBorderBox">Shop Now</Link></p>
          </div>
        </HomeColSection>

        <HomeColSection row="reverse" image={Sec5}>
          <div class="inner_right_bx">
            <h2 class="red-underline">Wedding Rings</h2>
            <p class="txt_body">With a Lifetime Manufacturing Defect Warranty, Price Match Guarantee, 30-day return policy and U.S. craftsmanship, you can be confident in each and every purchase.</p>
            <p class="txt_link m-0"><Link to="/jewelry/wedding-rings" className="brownBtnBorderBox">Shop Now</Link></p>
          </div>
        </HomeColSection>

        <FancyColorDiamonds /> 
        <CustomDesign />
        <Container fluid className='my-5'>
          <Row>
            <Col md={6} xs={12}>
              <div className="rcs_our_story_img_main">
                <iframe width="100%" height="400" src="https://www.youtube.com/embed/WMmf2po_G-E?si=3bLER977aSwLLMnc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="rcs_our_story_img_main">
              <iframe width="100%" height="400" src="https://www.youtube.com/embed/bxhg0tSDQHk?si=7KxYT0PuQY1juv5G" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>
        <Insta />
        <SignupTo />

        {/* 
        <Ringbuilderhome />
        <RingStyle />
        <ExploreCollection />
        <ShopDiamondShape />
        <Gemstones />
        <Virtualappointment />
        <Customer />
        <Insta />
        <SignupTo /> */}
      </>
    );
  };

export default Home